import React from "react";

const Ecommerce_new = () => {
  return (
    <>
      <div>
        <div className="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:gap-32 lg:grid-cols-2 items-center">
            <div className="relative overflow-hidden lg:order-last order-last flex flex-col rounded-3xl drop-shadow-[0_10px_20px_rgba(236,57,42,0.25)]">
              <img
                alt="Wallet Digital"
                src="https://img.invupos.com/clauweb/E-commerce/ecommerce-hero.webp"
                className="object-cover w-full h-[520px] rounded-3xl"
              />
            </div>
            <div className="lg:py-28 text-left">
              <h1 className="text-7xl font-extrabold sm:text-8xl text-black">
                <span className="block">Ecommerce</span>
                <span
                  className="mt-4 w-full ml-auto px-12 py-4 rounded-r-full text-white lg:text-7xl text-5xl font-bold flex items-center justify-end"
                  style={{
                    background:
                      "linear-gradient(to right, white, #f8857c, #fa5d5b)",
                  }}
                >
                  En segundos
                </span>
              </h1>
              <p className="mt-8 text-gray-500 text-2xl w-full tracking-normal leading-relaxed">
                Transformar tu negocio físico en un ecommerce exitoso es más
                fácil que nunca. Vende donde y cuando tus clientes quieran, con
                una plataforma diseñada para crecer contigo.
              </p>
              <div className="mt-8">
                <a
                  className="inline-block rounded-full border border-[#FE7066] bg-[#FE7066] px-16 py-4 text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
                  href="#"
                >
                  Comienza Hoy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-16 mx-auto  md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-10">
        <div className="p-8 rounded-3xl sm:p-12 bg-[#F1F1F1]">
          <h2 className="text-4xl sm:text-5xl font-extrabold text-[#1d1d1f]">
            Ofrece una experiencia de compra online nunca antes vista.
          </h2>
          <div className="flex flex-col items-center justify-between lg:flex-row mt-8 rounded-3xl bg-[#F1F1F1] lg:gap-12">
            {/* Lado izquierdo - Imagen del combo */}
            <div className="relative lg:w-1/2 mb-8 lg:mb-0 ">
              <img
                className="lg:w-full lg:h-full rounded-2xl object-cover object-top"
                src="https://img.invupos.com/clauweb/E-commerce/cta-ecommerce.webp"
                alt="Combo del día"
              />
            </div>

            {/* Lado derecho - Texto y lista */}
            <div className="lg:max-w-xl lg:pl-10 p-8">
              <ul className="space-y-8 text-gray-700 text-2xl list-disc">
                <li>
                  <span className="font-bold ">
                    Analítica avanzada: Datos en tiempo real para optimizar tus
                    ventas.
                  </span>{" "}
                  <br />
                </li>
                <li>
                  <span className="font-bold ">
                    Soporte continuo: Acompañamiento en cada paso del proceso.
                  </span>{" "}
                  <br />
                </li>
                <li>
                  <span className="font-bold ">
                    Promociones personalizadas: Envía descuentos especiales por
                    correo y haz que tus clientes siempre quieran volver.
                  </span>{" "}
                  <br />
                </li>
                <li>
                  <span className="font-bold ">
                    Programas de fidelidad: Recompensa a tus clientes frecuentes
                    y motívalos a regresar.
                  </span>{" "}
                  <br />
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Tarjetas informativas pequeñas */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12 text-center">
          <div className="bg-[#F8F8F8] rounded-2xl p-8">
            <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[200px] mx-auto">
              Pasarelas de pago{" "}
            </h4>
            <p className="text-gray-600 text-lg mt-3">
              Integración con múltiples métodos de pago para mayor comodidad.
            </p>
          </div>
          <div className="bg-[#F8F8F8] rounded-2xl p-8">
            <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[180px] mx-auto">
              Gestión de envíos
            </h4>
            <p className="text-gray-600 text-lg mt-3">
              Controla pedidos, inventario y logística desde una sola
              plataforma.
            </p>
          </div>
          <div className="bg-[#F8F8F8] rounded-2xl p-8">
            <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[220px] mx-auto">
              Integraciónes con sistema POS
            </h4>
            <p className="text-gray-600 text-lg mt-3">
              Conéctate de forma sencilla con Invu POS, gestionando tus ventas
              en tiempo real desde un solo lugar.
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-20">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-2xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
                E-commerce adaptado a tu comercio
              </h2>
              <p className="mt-8 text-gray-500 text-2xl w-full tracking-normal leading-relaxed">
                Con un E-commerce optimizado para móviles, tu negocio está
                siempre al alcance de tus clientes, sin importar dónde estén.
              </p>
            </div>
            <div className="grid gap-6 row-gap-6 sm:grid-cols-2">
              <div className="relative p-6 bg-[#FE7066] border rounded-3xl shadow-sm overflow-hidden space-y-4">
                <img
                  src="https://img.invupos.com/clauweb/E-commerce/Vector%20129%281%29.svg"
                  alt="Decorative background"
                  className="absolute inset-0 w-full h-full object-cover z-0 mt-16"
                />
                <div className="relative z-10 space-y-4">
                  <span className="text-5xl font-semibold text-white">80%</span>
                  <h3 className="inline-block mb-3 text-2xl font-bold text-white transition-colors duration-200 hover:text-deep-purple-accent-400">
                    Experiencia de Compra Rápida y Cómoda
                  </h3>
                </div>
              </div>

              <div className="p-6 bg-white border rounded-3xl shadow-sm space-y-4 items-center flex">
                <h3 className="inline-block mb-3 text-4xl font-semibold text-[#25272D] transition-colors duration-200 hover:text-deep-purple-accent-400">
                  Métricas y datos de ventas
                </h3>
              </div>

              <div className="relative p-6 bg-white border rounded-3xl shadow-sm overflow-hidden space-y-4">
                <img
                  src="https://img.invupos.com/clauweb/E-commerce/Vector%20128.svg"
                  alt="Decorative background"
                  className="absolute inset-0 w-full h-full object-cover z-0 mt-10"
                />
                <div className="relative z-10 space-y-4">
                  <h3 className="inline-block mb-3 text-4xl font-semibold text-[#25272D] transition-colors duration-200 hover:text-deep-purple-accent-400">
                    Pasarelas de pagos al instante
                  </h3>
                </div>
              </div>

              <div className="relative p-6 bg-white border rounded-3xl shadow-sm overflow-hidden space-y-4">
                <img
                  src="https://img.invupos.com/clauweb/E-commerce/Vector%20130.svg"
                  alt="Decorative background"
                  className="absolute inset-0 w-full h-full object-cover z-0 mt-4"
                />

                <div className="relative z-10 space-y-4">
                  <h3 className="inline-block mb-3 text-4xl font-semibold text-[#25272D] transition-colors duration-200 hover:text-deep-purple-accent-400">
                    Tracking de pedidos en linea
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-full  sm:h-full"
              src="https://img.invupos.com/clauweb/E-commerce/Mockups-2.webp"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="max-w-7xl px-6 py-6 mx-auto">
          <h2 className="text-5xl sm:text-6xl font-extrabold text-[#1d1d1f] py-10 text-left md:text-center lg:text-left">
            Llega a mis clientes y realiza más ventas con{" "}
            <span className="hidden lg:inline"></span>
            <span className="text-[#FE7066]">CLAU.IO</span>
          </h2>
          <div class="relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
            <div class="absolute w-full bg-[#F1F1F1] -z-10 md:h-[420px] rounded-3xl"></div>
            <div class="w-full p-6 bg-[#F1F1F1] md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
              <div class="mt-2 md:mx-6">
                <div className="mt-6">
                  <p className="font-light text-2xl mt-3 tracking-normal leading-relaxed text-gray-700 max-w-2xl">
                    Con nuestro programa de fidelización, tus clientes acumulan
                    puntos con cada compra, los cuales pueden canjear por
                    descuentos, productos exclusivos y beneficios especiales.
                  </p>
                  <a className="mt-6 inline-block rounded-full border border-[#FE7066] bg-[#FE7066] lg:px-12 px-8 py-3 text-sm md:text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden">
                    Empieza Ahora
                  </a>
                </div>
              </div>
              <img
                class="md:mx-6 lg:mt-0 mt-12 object-cover md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[18rem] md:rounded-2xl"
                src="https://img.invupos.com/clauweb/E-commerce/MadisonPass.webp"
                alt="client photo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecommerce_new;